export const ja = {
  'VtourModal.Vtour': 'バーチャルツアー',
  'VtourModal.GetStarted': 'バーチャルツアーを開始する',
  'VtourDate.DateAndTime': 'ツアー開始日時',
  'VtourForm.Participants': 'ツアー参加人数（1-20）',
  'VtourForm.UpToJA': '人まで',
  'VtourForm.UpToEN': ' ',
  'VtourCheck.Options': 'ツアーオプション',
  'VtourCheck.AllowComment': 'ユーザーのコメント送信を許可する',
  'VtourCheck.AllowLandmarkComment':
    'ユーザーのランドマークコメント送信を許可する',
  'VtourCheck.AllowEmoji': 'ユーザーの絵文字送信を許可する',
  'VtourCheck.AllowDisplayCommentList': 'ユーザーコメントの一覧表示を許可する',
  'VtourCheck.AllowGetLocation': '現在地取得を許可する',
  'VtourUrl.Publish': 'バーチャルツアー用のURLを発行する',
  'VtourUrl.UrlForUser': 'ユーザー配布用URL',
  'VtourUrl.UrlForGuide': 'ガイド用URL',
  'VtourButton.Enter': 'バーチャルツアー画面に入る',
};

export const en = {
  'VtourModal.Vtour': 'Virtual Tour',
  'VtourModal.GetStarted': 'Start a virtual tour',
  'VtourDate.DateAndTime': 'Tour start date and time',
  'VtourForm.Participants': 'Tour participants (1-20)',
  'VtourForm.UpToJA': ' ',
  'VtourForm.UpToEN': 'Up to',
  'VtourCheck.Options': 'Tour options',
  'VtourCheck.AllowComment': 'Allow users to send comments',
  'VtourCheck.AllowLandmarkComment': 'Allow users to send landmark comments',
  'VtourCheck.AllowEmoji': 'Allow users to send emoticons',
  'VtourCheck.AllowDisplayCommentList':
    'Allow user comments to be displayed in the comment list',
  'VtourCheck.AllowGetLocation': 'Allow to get current location',
  'VtourUrl.Publish': 'Publish URL for virtual tour',
  'VtourUrl.UrlForUser': 'URL for user distribution',
  'VtourUrl.UrlForGuide': 'URL for guide',
  'VtourButton.Enter': 'Enter the virtual tour',
};
