import React from 'react';
import { FormattedMessage } from 'react-intl';

type Props = {
  limit: number;
  setLimit: React.Dispatch<React.SetStateAction<number>>;
};

const VtourForm = React.memo((props: Props) => {
  const style: { [key: string]: React.CSSProperties } = {
    div: {
      marginBottom: '16px',
    },
    text: {
      fontWeight: 'bold',
      marginBottom: '4px',
    },
    select: {
      color: '#424242',
      padding: '0 8px',
      border: '1px solid #424242',
      height: '24px',
      fontSize: '16px',
      width: '64px',
    },
  };

  const handleChange = React.useCallback(
    (event: React.FormEvent<HTMLSelectElement>) => {
      props.setLimit(Number(event.currentTarget.value));
    },
    [props],
  );

  const option = Array.from(new Array(20)).map((_, index) => {
    const value = index + 1;
    return (
      <option value={value} key={value}>
        {value}
      </option>
    );
  });

  return (
    <div style={style.div}>
      <label>
        <p style={style.text}>
          <FormattedMessage id="VtourForm.Participants" />
        </p>
        <FormattedMessage id="VtourForm.UpToEN" />{' '}
        <select
          value={props.limit}
          onChange={handleChange}
          style={style.select}>
          {option}
        </select>{' '}
        <FormattedMessage id="VtourForm.UpToJA" />
      </label>
    </div>
  );
});

export default VtourForm;
