import { CharacterType } from '../domains/user/character';

export const Service = 'Stroly LAB';

export const Title = 'Virtual Map';

export const TourTitle = 'Vitual Tour';

export const Description =
  'Stroly LAB Virtual Map where all maps of Stroly.com can be experienced in virtual. Also supports communication with multiple members using the group function.';

export const Thumbnail = `${process.env.REACT_APP_SITE_URL}/ogp.jpg`;

export const DeportedUserName = '___deported_user___';

export const CharactersData = [
  {
    type: CharacterType.Boy,
    image: '/images/boy.gif',
    width: '28',
  },
  {
    type: CharacterType.Girl,
    image: '/images/girl.gif',
    width: '32',
  },
  {
    type: CharacterType.Baby,
    image: '/images/baby.gif',
    width: '34',
  },
  {
    type: CharacterType.Balloon,
    image: '/images/balloon.gif',
    width: '18',
  },
  {
    type: CharacterType.Bear,
    image: '/images/bear.gif',
    width: '30',
  },
  {
    type: CharacterType.Dog,
    image: '/images/dog.gif',
    width: '30',
  },
  {
    type: CharacterType.Blackcat,
    image: '/images/blackcat.gif',
    width: '58',
  },
  {
    type: CharacterType.Tigercat,
    image: '/images/tigercat.gif',
    width: '58',
  },
  {
    type: CharacterType.Whitecat,
    image: '/images/whitecat.gif',
    width: '34',
  },
];
