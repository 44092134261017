export const ja = {
  'ConnectionNameForm.Nickname': 'ニックネームを設定してください',
  'ConnectionNameForm.ErrorMessage':
    'ニックネームは半角英数字で設定してください。',
  'ConnectionImage.Character': 'キャラクターを選択してください',
  'ConnectionStartDate.DateAndTime': 'ツアー開始時間',
  'ConnectionBeforeTour.DateAndTime': '【ツアー開始時間】',
  'ConnectionBeforeTour.Message': '開始5分前からお入りいただけます。',
  'ConnectionBeforeTour.RefreshDateAndTime': '画面更新時の日時',
  'ConnectionBeforeTour.StartDate': '{startDate} から',
  'ConnectionBeforeTour.Refresh': '画面を更新する',
  'ConnectionButton.Start': 'はじめる',
  'ConnectionBrowse.BrowseMode': '閲覧モード',
  'ConnectionBrowse.BrowseModeForVtour': 'ツアーを見学する',
  'ConnectionModal.Message1': 'ツアー参加上限数に達しています。',
  'ConnectionModal.Message2': '「ツアーを見学する」からお入りください。',
  'ConnectionParticipants.Current': '現在の参加者数',
  'ConnectionParticipants.PeopleJA': '人',
  'ConnectionParticipants.Maximum': '（参加上限 {limit} 人まで）',
  'LocateButton.Share': '共有',
  'LocateButton.Stop': '停止',
  'VmapContainer.LocateButton.ConfrimMessage':
    'あなたの現在地を取得して、他のユーザーに共有します。よろしいですか？',
  'UserList.Guide': 'ガイド',
  'ExitButton.Exit': '退出',
  'VmapContainer.FinishedTour.AlertMessage':
    'ツアーの終了時間となりました。ご利用ありがとうございました。',
  'VmapContainer.ExitButton.ConfirmMessage': 'この地図から退出しますか？',
  'VmapContainer.DeportedUser.AlertMessage':
    'ガイドの操作によりツアーメンバーから外されました。閲覧モードに切り替わります。',
  'StrolyMap.DeportUser.ConfirmMessage': 'このユーザーを非表示にしますか？',
  'StrolyMap.LocateButton.AlertMessage': 'あなたの現在地は地図の範囲外です。',
  'Landmark.Move': '移動する',
  'Landmark.Comment': 'コメントする',
  'Landmark.Close': '閉じる',
  'CommentConatainer.CheckLandmark':
    '🏛ランドマーク「{landmark}」をチェックしました！',
  'CommentButton.Open': '開く',
  'CommentButton.Close': '閉じる',
  'CommentForm.ButtonLabel': 'メッセージ',
  'CommentSendMessage.Placeholder': 'メッセージ',
};

export const en = {
  'ConnectionNameForm.Nickname': 'Set Nickname',
  'ConnectionNameForm.ErrorMessage':
    'Set the "Nickname" in alphanumeric characters.',
  'ConnectionImage.Character': 'Select Your Character',
  'ConnectionStartDate.DateAndTime': 'Tour start date and time',
  'ConnectionBeforeTour.DateAndTime': '【Tour start date and time】',
  'ConnectionBeforeTour.Message':
    'You can enter from 5 minutes before the tour starts.',
  'ConnectionBeforeTour.RefreshDateAndTime': 'Date and time of refresh',
  'ConnectionBeforeTour.StartDate': 'From {startDate}',
  'ConnectionBeforeTour.Refresh': 'Refresh',
  'ConnectionButton.Start': 'Go!!',
  'ConnectionBrowse.BrowseMode': 'Go in browse mode',
  'ConnectionBrowse.BrowseModeForVtour': 'Browse tour',
  'ConnectionModal.Message1':
    'The maximum number of tour participants has been reached.',
  'ConnectionModal.Message2': 'Please enter from "Browse tour".',
  'ConnectionParticipants.Current': 'Current number of participants',
  'ConnectionParticipants.PeopleJA': ' ',
  'ConnectionParticipants.Maximum':
    '(Maximum number of participants is {limit})',
  'LocateButton.Share': 'SHARE',
  'LocateButton.Stop': 'STOP',
  'VmapContainer.LocateButton.ConfrimMessage':
    'Get your location and share it with others.',
  'UserList.Guide': 'Guide',
  'ExitButton.Exit': 'EXIT',
  'VmapContainer.FinishedTour.AlertMessage':
    "It's the end time of the tour. Thank you for your participation.",
  'VmapContainer.ExitButton.ConfirmMessage': 'Do you exit?',
  'VmapContainer.DeportedUser.AlertMessage':
    'You have been removed as a member by operating the guide. Switch to browse mode.',
  'StrolyMap.DeportUser.ConfirmMessage': 'Will you hide this user?',
  'StrolyMap.LocateButton.AlertMessage': 'Your location is outside the map.',
  'Landmark.Move': 'MOVE',
  'Landmark.Comment': 'COMMENT',
  'Landmark.Close': 'CLOSE',
  'CommentButton.Open': 'OPEN',
  'CommentConatainer.CheckLandmark': '🏛Check the landmark: {landmark}',
  'CommentButton.Close': 'CLOSE',
  'CommentForm.ButtonLabel': 'MESSAGE',
  'CommentSendMessage.Placeholder': 'Message',
};
