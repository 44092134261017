import React, { useCallback } from 'react';

type Props = {
  isCopyright?: boolean;
  color?: string;
  style?: { [key: string]: React.CSSProperties };
};

const Address = (props: Props) => {
  const style: { [key: string]: React.CSSProperties } = {
    address: {
      color: props.color || '#696969',
      fontWeight: 'bold',
      fontSize: '14px',
      fontStyle: 'normal',
      ...props.style,
    },
    a: {
      color: props.color || '#696969',
    },
  };

  const handleLinkClick = useCallback(() => {
    gtag('event', 'click', {
      event_category: 'button',
      event_label: 'Stroly',
    });
  }, []);

  const year = new Date().getFullYear();
  const prefix = props.isCopyright ? <>&copy; {year} </> : <>Powered by </>;

  return (
    <address style={style.address}>
      {prefix}
      <a
        style={style.a}
        href="https://stroly.com/"
        onClick={handleLinkClick}
        target="_blank"
        rel="noopener noreferrer">
        Stroly Inc.
      </a>
    </address>
  );
};

export default Address;
