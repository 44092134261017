import React from 'react';
import { FormattedMessage } from 'react-intl';
import Tooltip from '@material-ui/core/Tooltip';

import { Marker, Markers } from '../../domains/map';
import { User, UserType } from '../../domains/user/user.model';
import { CharacterType } from '../../domains/user/character';

import Paper from '@material-ui/core/Paper';

type Props = {
  user: User;
  isLogedIn: boolean;
  markers: Markers;
  panTo: (token: number) => void;
  children?: any;
};

const UserList = React.memo((props: Props) => {
  const style: { [key: string]: React.CSSProperties } = {
    paper: {
      zIndex: 1001,
      height: 'calc(100% - 16px)',
      width: '48px',
      boxSizing: 'border-box',
      textAlign: 'center',
      backgroundColor: 'rgba(240,240,240,0.9)',
      position: 'absolute',
      right: 0,
      boxShadow: 'none',
      borderRadius: 'unset',
    },
    ul: {
      boxSizing: 'border-box',
      listStyleType: 'none',
      margin: 0,
      padding: 0,
      overflowY: 'scroll',
      height: 'calc(100% - 16px - 54px - 50px)',
    },
    li: {
      borderBottom: '4px solid #bdbdbd',
      paddingBottom: '8px',
    },
    span: {
      display: 'inline-block',
      width: '32px',
      height: '32px',
      borderRadius: '50%',
      cursor: 'pointer',
      margin: '8px 8px 0',
      verticalAlign: 'middle',
      backgroundSize: 'cover',
    },
    browse: {
      cursor: 'standard',
      backgroundColor: '#929292',
      lineHeight: '32px',
      color: '#fff',
    },
    guide: {
      fontSize: '11px',
      fontWeight: 'bold',
      color: '#424242',
    },
    exit: {
      position: 'absolute',
      bottom: '2px',
      right: 0,
    },
  };

  const handleCharacterClick = React.useCallback(
    (token: number) => {
      props.panTo(token);
    },
    [props],
  );

  const list = Object.values(props.markers)
    .sort((user: Marker) => {
      return user.token === props.user.token ? -1 : 0;
    })
    .map((user: Marker) => {
      const character = {
        ...style.span,
        backgroundColor: `${user.color[1]}`,
        backgroundImage: `url('/images/${user.characterType}.png')`,
      };

      return (
        <li
          style={props.user.token === user.token ? style.li : {}}
          key={user.token}>
          <Tooltip title={user.name} placement="left">
            <span
              onClick={() => handleCharacterClick(user.token)}
              style={character}></span>
          </Tooltip>
          {user.characterType === CharacterType.Guide ? (
            <div style={style.guide}>
              <FormattedMessage id="UserList.Guide" />
            </div>
          ) : null}
        </li>
      );
    });

  const browseModeUser =
    props.user.type === UserType.Browse ? (
      <li style={style.li}>
        <span style={{ ...style.span, ...style.browse }}>No</span>
      </li>
    ) : null;

  const usersList = props.isLogedIn ? (
    <Paper style={style.paper}>
      {props.children[0]}
      <ul style={style.ul}>
        {browseModeUser}
        {list}
      </ul>
      <span style={style.exit}>{props.children[1]}</span>
    </Paper>
  ) : null;

  return usersList;
});

export default UserList;
