import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import { CharacterType } from '../../domains/user/character';
import * as Constants from '../../core/constants';

type Props = {
  name: string;
  color: string[];
  handleCharacterSelected: (character: CharacterType) => void;
};

const ConnectionImage = React.memo((props: Props) => {
  const style: { [key: string]: React.CSSProperties } = {
    text: {
      fontWeight: 'bold',
      marginBottom: '8px',
    },
    div: {
      width: '240px',
      margin: '0 auto 8px',
    },
    selected: {
      display: 'inline-block',
      cursor: 'pointer',
      padding: '4px',
      border: '3px solid #696969',
    },
    nonSelected: {
      display: 'inline-block',
      cursor: 'pointer',
      padding: '7px',
    },
    name: {
      fontSize: '14px',
      fontWeight: 'bold',
      padding: '2px 16px',
      borderRadius: '4px',
      color: '#fff',
      backgroundColor: props.color[0],
    },
  };

  const [selected, setSelected] = React.useState<CharacterType>(
    CharacterType.Boy,
  );

  const handleClick = useCallback(
    (character: CharacterType) => {
      setSelected(character);
      props.handleCharacterSelected(character);
    },
    [props],
  );

  const characters = Constants.CharactersData.map((character) => {
    return (
      <span
        key={character.type}
        style={
          selected === character.type ? style.selected : style.nonSelected
        }>
        <img
          src={character.image}
          alt={character.type}
          width={character.width}
          onClick={() => handleClick(character.type)}
        />
      </span>
    );
  });

  return (
    <div>
      <p style={style.text}>
        　<FormattedMessage id="ConnectionImage.Character" />
      </p>
      <div style={style.div}>{characters}</div>
      <span style={style.name}>{props.name || 'Nickname'}</span>
    </div>
  );
});

export default ConnectionImage;
