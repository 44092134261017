import React, { useCallback } from 'react';
import moment from 'moment';

import { UserType } from '../domains/user/user.model';
import { GuideOptions } from './AppContainer';
import { CharacterType } from '../domains/user/character';
import { MetaDataLocalized } from '../domains/map';

import Overlay from '../components/connection/Overlay';
import ConnectionModal from '../components/connection/ConnectionModal';
import ConnectionImage from '../components/connection/ConnectionImage';
import ConnectionGuideImage from '../components/connection/ConnectionGuideImage';
import ConnectionParticipants from '../components/connection/ConnectionParticipants';
import ConnectionButton from '../components/connection/ConnectionButton';
import ConnectionNameForm from '../components/connection/ConnectionNameForm';
import ConnectionStartDate from '../components/connection/ConnectionStartDate';
import ConnectionBrowse from '../components/connection/ConnectionBrowse';

type Props = {
  isLogedIn: boolean;
  userType: UserType;
  guideOptions: GuideOptions;
  isFull: boolean;
  isBeforeTour: boolean;
  participants: number;
  startDate: moment.Moment | undefined;
  name: string;
  color: string[];
  metaData: MetaDataLocalized;
  handleCharacterSelected(characterType: CharacterType): void;
  handleNameChange(name: string): void;
  handleConnectionButtonClick(): void;
  handleBrowseModeClick(): void;
};

const ConnectionContainer = React.memo((props: Props) => {
  const [isDisabled, setIsDisabled] = React.useState(true);
  const [isDisplay, setIsDisplay] = React.useState(true);

  // todo　映画村案件が終わったら、以下の2バイト文字判定の部分消す
  const [isError, setIsError] = React.useState(false);
  const handleChange = useCallback(
    (name: string) => {
      if (props.guideOptions.isGuideMode && props.userType !== UserType.Guide) {
        if (name.match(/[^A-Z^a-z\d-]/)) {
          setIsError(true);
        } else {
          setIsError(false);
        }
      }
      props.handleNameChange(name);

      if (name.length > 0) {
        setIsDisabled(false);
        return;
      }
      setIsDisabled(true);
    },
    [props],
  );

  // todo　映画村案件が終わったら、以下の2バイト文字判定の部分消す
  const handleClick = useCallback(() => {
    if (!isError) {
      props.handleConnectionButtonClick();
    }
  }, [props]);

  const handleBrowseModeClick = useCallback(() => {
    props.handleBrowseModeClick();
  }, [props]);

  React.useEffect(() => {
    if (props.isLogedIn) {
      setIsDisplay(false);
      return;
    }
    setIsDisplay(true);
  }, [props.isLogedIn]);

  return (
    <Overlay isDisplay={isDisplay}>
      <ConnectionModal
        title={props.metaData?.title}
        userType={props.userType}
        isGuideMode={props.guideOptions?.isGuideMode}
        isFull={props.isFull}
        isBeforeTour={props.isBeforeTour}
        startDate={props.startDate}>
        <ConnectionNameForm
          isGuideMode={props.guideOptions?.isGuideMode}
          userType={props.userType}
          isError={isError}
          name={props.name}
          handleChange={handleChange}
        />
        {props.userType === UserType.Guide ? (
          <ConnectionGuideImage
            handleCharacterSelected={props.handleCharacterSelected}
          />
        ) : (
          <ConnectionImage
            name={props.name}
            color={props.color}
            handleCharacterSelected={props.handleCharacterSelected}
          />
        )}
        <ConnectionStartDate
          guideOptions={props.guideOptions}
          startDate={props.startDate}
        />
        <ConnectionButton handleClick={handleClick} isDisabled={isDisabled} />
        <ConnectionParticipants
          participants={props.participants}
          limit={props.guideOptions?.limit}
        />
        <ConnectionBrowse
          isGuideMode={props.guideOptions.isGuideMode}
          handleBrowseModeClick={handleBrowseModeClick}
        />
      </ConnectionModal>
    </Overlay>
  );
});

export default ConnectionContainer;
