import React from 'react';
import { FormattedMessage } from 'react-intl';

type Props = {
  url: string;
  token: number | undefined;
  createVtourId: () => void;
};

const VtourUrl = React.memo((props: Props) => {
  const style: { [key: string]: React.CSSProperties } = {
    div: {
      marginBottom: '16px',
    },
    button: {
      margin: '0 0 16px',
      cursor: 'pointer',
      backgroundColor: '#696969',
      color: '#fff',
      padding: '4px 16px',
      border: 'none',
      fontWeight: 'bold',
      borderRadius: '40px',
      boxShadow: '0 1px 2px #212121',
    },
    p: {
      fontSize: '14px',
      margin: 0,
    },
    input: {
      margin: '0 auto 8px',
      color: '#424242',
      padding: '4px 8px 8px',
      border: '1px solid #424242',
      height: '24px',
      lineHeight: '12px',
      fontSize: '12px',
      maxWidth: '320px',
      WebkitUserSelect: 'text',
      userSelect: 'all',
      overflowX: 'scroll',
      overflowY: 'hidden',
      backgroundColor: '#fff',
      whiteSpace: 'nowrap',
    },
  };

  const handleClick = React.useCallback(() => {
    props.createVtourId();
  }, [props]);

  return (
    <div style={style.div}>
      <button style={style.button} onClick={handleClick} disabled={!!props.url}>
        <FormattedMessage id="VtourUrl.Publish" />
      </button>

      <p style={style.p}>
        <FormattedMessage id="VtourUrl.UrlForUser" />
      </p>
      <p style={style.input}>
        {props.url ? `${process.env.REACT_APP_SITE_URL}${props.url}` : ''}
      </p>

      <p style={style.p}>
        <FormattedMessage id="VtourUrl.UrlForGuide" />
      </p>
      <p style={style.input}>
        {props.url
          ? `${process.env.REACT_APP_SITE_URL}${props.url}/${props.token}`
          : ''}
      </p>
    </div>
  );
});

export default VtourUrl;
