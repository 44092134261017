import React, { useState, useEffect, useRef, useCallback } from 'react';
import { injectIntl, IntlShape } from 'react-intl';
import { History } from 'history';

import { MapService } from '../domains/map';
import { GuideOptions } from './AppContainer';
import { ConnectionsRepository } from '../repositories/connections.repository';
import {
  updateUser,
  updateIsDisabled,
  updateComments,
  deleteComments,
  updateIsLoading,
  updateIsSharing,
  updateMarkers,
} from '../core/reducer';
import { StateContext, DispatchContext } from '../core/contexts';
import { CharacterType, Character } from '../domains/user/character';
import { CommentMessage, LocationMessage } from '../domains/ws/message.model';
import { LatLng } from 'leaflet';
import { UserType } from '../domains/user/user.model';
import * as Constants from '../core/constants';
import { useWS } from './hooks/useWS';
import { useAvailabilityService } from './hooks/useAvailabilityService';

import StrolyMap from '../components/map/StrolyMap';
import UserList from '../components/map/UserList';
import CommentContainer from './CommentContainer';
import ConnectionContainer from './ConnectionContainer';
import CentralIcon from '../components/map/CentralIcon';
import Snackbar from '../core/components/Snackbar';
import ExitButton from '../components/map/ExitButton';
import LocateButton from '../components/map/LocateButton';
import Address from '../core/components/Address';

type Props = {
  intl: IntlShape;
  history: History;
  mapService: MapService;
  guideOptions: GuideOptions;
  mapID: string;
  protocol: string;
};

const VmapContainer = (props: Props) => {
  const state = React.useContext(StateContext);
  const dispatch = React.useContext(DispatchContext);
  const [isLogedIn, setIsLogedIn] = useState(false);
  const [isChatOpen, setIsChatOpen] = useState(false);

  const isShareMode = useRef<boolean>(state.isSharing);

  const [name, setName] = useState<string>('');
  const [characterType, setCharacterType] = useState<CharacterType>(
    CharacterType.Boy,
  );
  const [character, setCharacter] = useState<Character>(
    new Character('', CharacterType.Boy),
  );

  const connectionsRepository = useRef(new ConnectionsRepository());

  const [isSnackbarOpen, setIsSnackbarOpen] = useState<boolean>(false);
  const [exitUser, setExitUser] = useState<string>('');

  const availabilityService = useAvailabilityService(
    props.history,
    dispatch,
    props.protocol,
    props.guideOptions.limit,
  );
  useEffect(() => {
    if (!props.guideOptions?.isGuideMode) {
      return;
    }
    const message = props.intl.formatMessage({
      id: 'VmapContainer.FinishedTour.AlertMessage',
    });
    availabilityService.checkIsAfterTour(message);
    availabilityService.checkStartDateAndParticipants();
  }, []);

  /**
   * WebSocketの接続が開始された時の処理
   */
  function handleWSOpen() {
    dispatch(updateIsDisabled(false));
    if (isShareMode.current === false && isBrowseMode.current === false) {
      props.mapService.createCentralMarker(
        state.user.token,
        state.user.color,
        character,
      );
    }
  }
  /**
   * WebSocketの接続が切れた時の処理
   */
  function handleWSClose(event: CloseEvent) {
    console.log(
      'Socket for locaion is closed. Reconnect will be attempted in 2 second.',
      event.reason,
    );
    setTimeout(() => {
      locationWS.connect();
    }, 2000);
  }
  /**
   * WebSocketサーバーからメッセージを受け取った時の処理
   */
  function handleWSMessage(event: MessageEvent) {
    const sendedMarker = JSON.parse(event.data);
    if (state.user.token > sendedMarker.id) return;

    switch (sendedMarker.task) {
      case 'put':
        break;
      case 'move':
        break;
      case 'remove':
        break;

      /** 現在地マーカー送信時 */
      case 'location':
        if (
          !props.mapService.locations[sendedMarker.token] &&
          sendedMarker.token === state.user.token
        ) {
          props.mapService.panTo(sendedMarker.lat, sendedMarker.lng);
        }
        props.mapService.putLocationMarker(sendedMarker);

        if (!props.mapService.locationList[sendedMarker.token]) {
          props.mapService.locationList = {
            ...props.mapService.locationList,
            [sendedMarker.token]: sendedMarker,
          };
          dispatch(updateMarkers(props.mapService.locationList));
        }

        dispatch(updateIsDisabled(false));
        break;
      case 'removeLocation':
        props.mapService.removeLocationMarker(sendedMarker.token);
        dispatch(updateIsDisabled(false));
        break;
      case 'centralmove':
        if (!props.mapService.locationList[sendedMarker.token]) {
          props.mapService.locationList = {
            ...props.mapService.locationList,
            [sendedMarker.token]: sendedMarker,
          };
          dispatch(updateMarkers(props.mapService.locationList));
        }

        props.mapService.moveCentralMarker(sendedMarker);
        break;
      case 'hideCentralMarker':
        props.mapService.removeCentralMarker(sendedMarker);
        break;
      case 'removeCentralMarker':
        const isMe = state.user.token === sendedMarker.token;
        props.mapService.removeCentralMarker(sendedMarker);
        props.mapService.removeLocationMarker(sendedMarker.token);
        delete props.mapService.locationList[sendedMarker.token];
        dispatch(updateMarkers(props.mapService.locationList));
        if (!isMe && sendedMarker.name !== Constants.DeportedUserName) {
          setExitUser(sendedMarker.name);
          setIsSnackbarOpen(true);
        }

        if (isMe && sendedMarker.name === Constants.DeportedUserName) {
          dispatch(
            updateUser({
              type: UserType.Browse,
            }),
          );

          const message = props.intl.formatMessage({
            id: 'VmapContainer.DeportedUser.AlertMessage',
          });
          alert(message);

          dispatch(updateIsLoading(true));
          connectionsRepository.current
            .delete(props.protocol, String(state.user.token))
            .then(() => {
              dispatch(updateIsLoading(false));
            })
            .catch((error) => {
              dispatch(updateIsLoading(false));
              props.history.push(
                `/error/${props.protocol}`,
                props.history.location.pathname,
              );
            });
        }

        if (sendedMarker.name === Constants.DeportedUserName) {
          dispatch(deleteComments(sendedMarker.token));
        }
        break;
    }
  }
  const locationWS = useWS<LocationMessage>(
    `${process.env.REACT_APP_LOCATION_SHARING_SERVER}/location`,
    props.protocol,
    {
      handleWSOpen,
      handleWSClose,
      handleWSMessage,
    },
  );

  /** ログイン後の処理（地図画面表示前の処理） */
  function initVirtualMap() {
    if (!isLogedIn) {
      setIsLogedIn(true);
    }
    if (!locationWS.get()) {
      locationWS.connect();
    }
  }

  async function handleBrowseModeClick() {
    gtag('event', 'login', {
      event_category: 'selectedCharacter',
      event_label: 'browse mode',
    });

    if (props.guideOptions?.isGuideMode) {
      await availabilityService.checkInBrowseMode();
    }

    dispatch(
      updateUser({
        type: UserType.Browse,
      }),
    );
  }
  const isBrowseMode = useRef(false);
  useEffect(() => {
    if (state.user.type === UserType.Browse) {
      initVirtualMap();
      isBrowseMode.current = true;
    } else {
      isBrowseMode.current = false;
    }
  }, [state.user.type]);

  function panToComment(message: CommentMessage) {
    if (message.type !== 'landmark') {
      props.mapService.putCommentMarker(message);
    }

    props.mapService.panTo(message.latlng.lat, message.latlng.lng);

    gtag('event', 'click', {
      event_category: 'panTo',
      event_label: 'CommentList',
    });
  }

  function panToLandmark(latlng: LatLng) {
    props.mapService.panTo(latlng.lat, latlng.lng);

    gtag('event', 'click', {
      event_category: 'panTo',
      event_label: 'Landmark',
    });
  }

  function controlLocate() {
    dispatch(updateIsDisabled(true));
    if (isShareMode.current) {
      props.mapService.panToLocationMarker(state.user.token);
      setTimeout(() => {
        props.mapService.stopGettingCurrentLocation();
        props.mapService.createCentralMarker(
          state.user.token,
          state.user.color,
          character,
        );
      }, 500);
      dispatch(updateIsSharing(!state.isSharing));
    } else {
      const message = props.intl.formatMessage({
        id: 'VmapContainer.LocateButton.ConfrimMessage',
      });
      const isOK = window.confirm(message);
      if (!isOK) {
        dispatch(updateIsDisabled(false));
        return;
      }
      props.mapService.getCurrentLocation();
    }
    gtag('event', 'click', {
      event_category: 'button',
      event_label: isShareMode.current ? 'Stop Sharing' : 'Share Your Location',
    });
  }

  function handleChatButtonClick() {
    setIsChatOpen(!isChatOpen);
  }

  function dispatchUpdateComments(comment: CommentMessage) {
    dispatch(updateComments(comment));
  }

  function setCommentToCentralMarker(message: CommentMessage) {
    props.mapService.setCommentToLocationMarker(message);
    props.mapService.setCommentToCentralMarker(message);
  }

  function handleNameChange(name: string) {
    setName(name);
    setCharacter(new Character(name, characterType));
  }

  function handleCharacterSelected(characterType: CharacterType) {
    setCharacterType(characterType);
    setCharacter(new Character(name, characterType));
  }

  async function handleConnectionButtonClick() {
    gtag('event', 'login', {
      event_category: 'selectedCharacter',
      event_label: character.getCharacterType(),
    });

    if (
      !props.guideOptions?.isGuideMode ||
      state.user.type === UserType.Guide
    ) {
      initVirtualMap();
      return;
    }

    const isError = await availabilityService.checkStartDateAndParticipants();
    if (isError) {
      return;
    }

    dispatch(updateIsLoading(true));
    connectionsRepository.current
      .post(props.protocol, String(state.user.token))
      .then(() => {
        dispatch(updateIsLoading(false));
        initVirtualMap();
      })
      .catch((error) => {
        dispatch(updateIsLoading(false));
        props.history.push(
          `/error/${props.protocol}`,
          props.history.location.pathname,
        );
      });
  }

  function sendRemoveCentralMarker(isLeaving: boolean) {
    const task = isLeaving ? 'removeCentralMarker' : 'hideCentralMarker';
    const locationMassage = {
      room: props.protocol as string,
      token: state.user.token,
      color: state.user.color,
      id: new Date().getTime(),
      name: character.getName(),
      characterType: character.getCharacterType(),
      lat: NaN,
      lng: NaN,
      task,
    };
    locationWS.send(locationMassage);
  }

  function handleCharacterInUserListClick(token: number) {
    props.mapService.panToCentralMarker(token);
    props.mapService.panToLocationMarker(token);

    gtag('event', 'click', {
      event_category: 'panTo',
      event_label: 'UserList',
    });
  }

  useEffect(() => {
    isShareMode.current = state.isSharing;
  }, [state.isSharing]);

  /**
   * 画面やタブを閉じる直前で実行したい処理
   */
  const exit = useCallback(() => {
    gtag('event', 'click', {
      event_category: 'button',
      event_label: 'Exit',
    });

    const confirmMessage = props.intl.formatMessage({
      id: 'VmapContainer.ExitButton.ConfirmMessage',
    });
    const isExit = window.confirm(confirmMessage);
    if (isExit) {
      window.location.reload();
    }
  }, []);

  function handleBeforeunload(event: BeforeUnloadEvent) {
    if (
      props.guideOptions.isGuideMode &&
      isLogedIn &&
      state.user.type === UserType.User
    ) {
      connectionsRepository.current.delete(
        props.protocol,
        String(state.user.token),
      );
      sendRemoveCentralMarker(true);
      event.returnValue = '';
      window.removeEventListener('unload', handleUnload);
      return;
    }

    if (isLogedIn && state.user.type !== UserType.Browse) {
      sendRemoveCentralMarker(true);
    }
  }

  function handleUnload() {
    if (
      props.guideOptions.isGuideMode &&
      isLogedIn &&
      state.user.type === UserType.User
    ) {
      connectionsRepository.current.deleteByXHR(
        props.protocol,
        String(state.user.token),
      );
      sendRemoveCentralMarker(true);
      return;
    }

    if (isLogedIn && state.user.type !== UserType.Browse) {
      sendRemoveCentralMarker(true);
    }
  }

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeunload);
    window.addEventListener('unload', handleUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeunload);
      window.removeEventListener('unload', handleUnload);
    };
  }, [character, isLogedIn, state.user.type]);

  const setSelectedLandmarkMarker = useCallback(() => {
    props.mapService.setSelectedLandmarkMarker();

    gtag('event', 'click', {
      event_category: 'button',
      event_label: 'landmark close',
    });
  }, [props.mapService]);

  return (
    <>
      <ConnectionContainer
        isLogedIn={isLogedIn}
        userType={state.user.type}
        guideOptions={props.guideOptions}
        isFull={availabilityService.isFull}
        isBeforeTour={availabilityService.isBeforeTour}
        participants={availabilityService.participants}
        startDate={availabilityService.startDate}
        name={name}
        color={state.user.color}
        metaData={state.metaData}
        handleCharacterSelected={handleCharacterSelected}
        handleNameChange={handleNameChange}
        handleConnectionButtonClick={handleConnectionButtonClick}
        handleBrowseModeClick={handleBrowseModeClick}
      />
      <StrolyMap
        isLogedIn={isLogedIn}
        user={state.user}
        mapService={props.mapService}
        protocol={props.protocol}
        character={character}
        mapID={props.mapID}
        dispatch={dispatch}
        sendDatatoWS={locationWS.send}
        sendRemoveCentralMarker={sendRemoveCentralMarker}>
        <>
          {state.user.type === UserType.Browse ? <CentralIcon /> : null}
          <CommentContainer
            user={state.user}
            name={name}
            characterType={characterType}
            guideOptions={props.guideOptions}
            isLogedIn={isLogedIn}
            isChatOpen={isChatOpen}
            isShareMode={isShareMode.current}
            protocol={props.protocol}
            comments={state.comments}
            landmark={state.landmarkContent}
            mapService={props.mapService}
            setCommentToCentralMarker={setCommentToCentralMarker}
            setSelectedLandmarkMarker={setSelectedLandmarkMarker}
            panToLandmark={panToLandmark}
            panToComment={panToComment}
            handleChatButtonClick={handleChatButtonClick}
            dispatchUpdateComments={dispatchUpdateComments}
          />
          <UserList
            user={state.user}
            isLogedIn={isLogedIn}
            markers={state.markers}
            panTo={handleCharacterInUserListClick}>
            {state.user.type === UserType.Guide ||
            (state.user.type !== UserType.Browse &&
              props.guideOptions.isLocateEnabled) ? (
              <LocateButton
                isDisabled={state.isDisabled}
                isSharing={state.isSharing}
                controlLocate={controlLocate}
              />
            ) : null}
            <ExitButton handleExitButtonClick={exit} />
          </UserList>
          <Address
            color="#fff"
            style={
              {
                textShadow: '0 0 2px #424242',
                position: 'absolute',
                bottom: '4px',
                left: '4px',
                color: '#fff',
              } as {
                [key: string]: React.CSSProperties;
              }
            }
          />
        </>
      </StrolyMap>
      <Snackbar
        isSnackbarOpen={isSnackbarOpen}
        setIsSnackbarOpen={setIsSnackbarOpen}>
        <span>{exitUser} left the map.</span>
      </Snackbar>
    </>
  );
};

export default injectIntl(VmapContainer);
