import React from 'react';
import { FormattedMessage } from 'react-intl';

type Props = {
  url: string;
  goMap: () => void;
};

const VtourButton = React.memo((props: Props) => {
  const style: { [key: string]: React.CSSProperties } = {
    button: {
      margin: '32px 0 0',
      cursor: 'pointer',
      width: '216px',
      lineHeight: '32px',
      borderRadius: '25px',
      border: 0,
      boxShadow: '0 1px 2px #212121',
      fontSize: '14px',
      backgroundColor: '#fbb63f',
      color: '#424242',
      fontWeight: 'bold',
    },
  };

  const handleClick = React.useCallback(() => {
    props.goMap();
  }, [props]);

  return (
    <button style={style.button} onClick={handleClick} disabled={!!!props.url}>
      <FormattedMessage id="VtourButton.Enter" />
    </button>
  );
});

export default VtourButton;
