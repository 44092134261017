import React from 'react';
import { FormattedMessage } from 'react-intl';
import Button from '@material-ui/core/Button';
import moment from 'moment';

type Props = {
  startDate: moment.Moment | undefined;
};

const ConnectionBeforeTour = React.memo((props: Props) => {
  const style: { [key: string]: React.CSSProperties } = {
    date: {
      fontSize: '20px',
    },
    text: {
      fontWeight: 'bold',
      color: 'rgba(211,47,47 ,1)',
    },
  };

  const [currentDate, setCurrentDate] = React.useState(
    moment().format('YYYY-MM-DD HH:mm:ss'),
  );
  const handleClick = React.useCallback(() => {
    const currentDate = moment();
    setCurrentDate(currentDate.format('YYYY-MM-DD HH:mm:ss'));
    if (moment(props.startDate).diff(currentDate) < 60 * 1000 * 5) {
      window.location.reload();
    }
  }, [props.startDate]);

  return (
    <div>
      <p>
        <b>
          <FormattedMessage id="ConnectionBeforeTour.DateAndTime" />
        </b>
        <br />
        <b style={style.date}>
          <FormattedMessage
            id="ConnectionBeforeTour.StartDate"
            values={{
              startDate: props.startDate?.format('YYYY-MM-DD HH:mm'),
            }}
          />
        </b>
      </p>
      <p style={style.text}>
        <FormattedMessage id="ConnectionBeforeTour.Message" />
      </p>
      <p>
        <FormattedMessage id="ConnectionBeforeTour.RefreshDateAndTime" />
        <br />
        <b>{currentDate}</b>
      </p>
      <Button variant="contained" color="primary" onClick={handleClick}>
        <FormattedMessage id="ConnectionBeforeTour.Refresh" />
      </Button>
    </div>
  );
});

export default ConnectionBeforeTour;
