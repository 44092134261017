export const ja = {
  'InvalidUrlError.Message': 'このツアーは終了しました。',
  'Error.Message': 'エラーが発生しました。',
  'Error.Back': '元の画面に戻る',
  'Error.BackToStroly': 'Stroly.comに戻る',
};

export const en = {
  'InvalidUrlError.Message': 'This tour has ended.',
  'Error.Message': 'An error has occurred.',
  'Error.Back': 'Back',
  'Error.BackToStroly': 'Back to stroly.com',
};
