import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import * as L from 'leaflet';

import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import SendIcon from '@material-ui/icons/Send';

import { UserType } from '../../domains/user/user.model';
import { EmojiName } from '../../domains/ws/emoji';
import { GuideOptions } from '../../containers/AppContainer';

import CommentEmoji from './CommentEmoji';
import CommentSendMessage from './CommentSendMessage';
import CommentButton from './CommentButton';

type Props = {
  userType: UserType;
  guideOptions: GuideOptions;
  comment: string;
  isDisabled: boolean;
  isChatOpen: boolean;
  handleSendMessage(): void;
  handleSendEmoji(emoji: EmojiName): void;
  handleChange(value: string): void;
  handleChatButtonClick(): void;
};

const CommentForm = React.memo((props: Props) => {
  const style: { [key: string]: React.CSSProperties } = {
    layout: {
      width: '320px',
      height: '40px',
      left: 'calc(50% - 160px)',
      position: 'absolute',
      bottom: '24px',
      textAlign: 'center',
      zIndex: 1002,
    },
    div: {
      display: 'inline-block',
      verticalAlign: 'middle',
    },
    paper: {
      padding: '0 8px',
      minWidth: '32px',
      display: 'flex',
      alignItems: 'center',
      margin: '0 auto',
      height: '40px',
    },
    layoutMobile: {
      width: '200px',
      height: '40px',
      left: 'calc(50% - 100px)',
      position: 'absolute',
      top: '0px',
      textAlign: 'center',
      zIndex: 1002,
    },
    paperMobile: {
      padding: '0 8px',
      minWidth: '32px',
      display: 'flex',
      alignItems: 'center',
      margin: '0 auto',
      height: '40px',
      borderRadius: '0 0 4px 4px',
    },
    span: {
      display: 'inline-block',
      color: '#424242',
      width: '64px',
      height: '40px',
      margin: '10px 0 0',
      padding: 0,
      textAlign: 'center',
      fontSize: '10px',
      fontWeight: 'bold',
      cursor: 'pointer',
    },
    divider: {
      height: 24,
      margin: '4px 8px',
    },
    comment: {},
    input: {
      boxSizing: 'border-box',
      color: '#424242',
      width: 'calc(100% - 56px)',
      height: '40px',
      fontSize: '16px',
      borderRadius: '4px 0 0 4px',
      border: 0,
      verticalAlign: 'middle',
    },
  };

  const [isKeybordOpen, setIsKeybordOpen] = React.useState(false);
  React.useEffect(() => {
    /**
     * ソフトウェアキーボードが引っ込んだ時
     */
    function handleDomFocusOut(event: any) {
      const inputName = event.target.name;
      if (L.Browser.mobile && inputName === 'comment' && !!!props.comment) {
        setIsKeybordOpen(false);
      }
    }
    document.addEventListener('DOMFocusOut', handleDomFocusOut);

    return () => {
      document.removeEventListener('DOMFocusOut', handleDomFocusOut);
    };
  }, [props.comment]);

  const handleMessageClick = useCallback(() => {
    if (isKeybordOpen) {
      setIsKeybordOpen(false);
      return;
    }
    setIsKeybordOpen(true);
  }, [isKeybordOpen]);

  return (
    <>
      {isKeybordOpen ? (
        <div style={style.layoutMobile}>
          <Paper style={style.paperMobile}>
            <CommentSendMessage
              comment={props.comment}
              isDisabled={props.isDisabled}
              handleChange={props.handleChange}
              handleSendMessage={props.handleSendMessage}
              setIsKeybordOpen={setIsKeybordOpen}
            />
            <Divider style={style.divider} orientation="vertical" />
            <CommentButton
              isChatOpen={props.isChatOpen}
              handleChatButtonClick={props.handleChatButtonClick}
            />
          </Paper>
        </div>
      ) : null}
      <div style={style.layout}>
        <div style={style.div}>
          <Paper style={style.paper}>
            {(props.userType === UserType.Guide ||
              (props.userType === UserType.User &&
                props.guideOptions?.isCommentEnabled)) &&
            !L.Browser.mobile ? (
              <>
                <CommentSendMessage
                  comment={props.comment}
                  isDisabled={props.isDisabled}
                  handleChange={props.handleChange}
                  handleSendMessage={props.handleSendMessage}
                  setIsKeybordOpen={setIsKeybordOpen}
                />
                <Divider style={style.divider} orientation="vertical" />
              </>
            ) : (props.userType === UserType.Guide ||
                (props.userType === UserType.User &&
                  props.guideOptions?.isCommentEnabled)) &&
              L.Browser.mobile ? (
              <>
                <span style={style.span} onClick={handleMessageClick}>
                  <SendIcon
                    fontSize="small"
                    style={{ verticalAlign: 'middle', color: '#e6b336' }}
                  />
                  <br />
                  <FormattedMessage id="CommentForm.ButtonLabel" />
                </span>
                <Divider style={style.divider} orientation="vertical" />
              </>
            ) : null}
            {props.userType === UserType.Guide ||
            (props.userType === UserType.User &&
              props.guideOptions?.isEmojiEnabled) ? (
              <>
                <CommentEmoji
                  emoji={EmojiName.like}
                  label={'like'}
                  handleSendEmoji={props.handleSendEmoji}
                />
                <CommentEmoji
                  emoji={EmojiName.love}
                  label="love"
                  handleSendEmoji={props.handleSendEmoji}
                />
                <CommentEmoji
                  emoji={EmojiName.look}
                  label="look"
                  handleSendEmoji={props.handleSendEmoji}
                />
                <Divider style={style.divider} orientation="vertical" />
              </>
            ) : null}
            <CommentButton
              isChatOpen={props.isChatOpen}
              handleChatButtonClick={props.handleChatButtonClick}
            />
          </Paper>
        </div>
      </div>
    </>
  );
});

export default CommentForm;
