import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { useCheckbox } from '../../containers/hooks/useCheckbox';

const ConnectionConsent = React.memo(() => {
  const style: { [key: string]: React.CSSProperties } = {
    title: {
      textAlign: 'center',
      fontWeight: 'bold',
    },
    article: {
      display: 'block',
      lineHeight: '1.6',
      color: '#424242',
      textAlign: 'justify',
      marginBottom: '16px',
    },
    label: {
      cursor: 'pointer',
    },
    input: {
      cursor: 'pointer',
    },
    underLine: {
      paddingBottom: '16px',
      borderBottom: '1px solid #bdbdbd',
    },
  };

  const [open, setOpen] = React.useState(true);
  const [scroll] = React.useState<DialogProps['scroll']>('paper');

  const handleAgreeClick = () => {
    gtag('event', 'click', {
      event_category: 'vtour',
      event_label: 'ツアーに参加する',
    });
    setOpen(false);
  };

  const handleDisagreeClick = () => {
    gtag('event', 'click', {
      event_category: 'vtour',
      event_label: 'ツアーに参加しない',
    });
    window.location.href = 'https://www.toei-eigamura.com/';
  };

  const descriptionElementRef = React.useRef<HTMLElement>(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const consent = useCheckbox(0);

  return (
    <Dialog
      open={open}
      scroll={scroll}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description">
      <DialogTitle id="scroll-dialog-title" style={style.title}>
        参加にあたっての同意書
      </DialogTitle>
      <DialogContent dividers={scroll === 'paper'}>
        <DialogContentText
          id="scroll-dialog-description"
          ref={descriptionElementRef}
          tabIndex={-1}>
          <span style={style.article}>
            皆さまが楽しく快適にバーチャルツアーに参加いただくために、参加や見学いただくお客さまに適用される条件を定めさせていただきました。本サービスをご利用の場合は、内容を必ず事前にご確認の上、同意いただく必要があります。（尚、お客さまが本サービスのご利用を開始した場合、当社はお客さまが全て同意いただいたものとみなします。もし条件と異なる行為と判断した場合は、お客様に断りなく制限させてい
            ただく場合がございます。）
          </span>

          <span style={{ ...style.article, ...style.underLine }}>
            下記をよくお読みいただきV字チェックをお願いします。
          </span>

          <span style={style.article}>
            １）バーチャル・ツアーには子供から大人まで様々な人がプレイしています。むやみと身勝手な振る舞い、周りを気にせず行った操作などが原因で思わぬトラブルに発展したり、巻き込まれたりする場合がございます。マナーを守り、相手に思いやりをもってご参加ください。
          </span>

          <span style={style.article}>
            ２）本サービスのご利用には、PC、モバイルなどの端末と電気通信サービスの利用又はWi-Fiネットワークなどへの接続が必要です。
          </span>

          <span style={style.article}>
            ３）当該接続等により発生する通信料は、お客さまの負担となります。
          </span>

          <span style={style.article}>
            ４）バーチャル・ツアーのアバターでのご参加は、定員１０名となっています。
          </span>

          <span style={style.article}>
            ５）定員を超えた場合は、ツアー実施状況を閲覧する「見学のみ」の参加となります。（アバター操作はご利用いただけません。）
          </span>

          <span style={style.article}>
            ６）バーチャル・ツアーは、ガイド役「十蘭」の誘導と指示に従ってください。
          </span>

          <span style={style.article}>
            ７）ツアーに夢中になってしまうと、他のことに目がいかなくなる場合があります。モニターやディスプレイから少し距離を置き、周囲の環境にも充分ご注意下さい。
          </span>

          <span style={style.article}>
            ８）バーチャル・ツアー参加者は第三者の行為、又は天災地変、火災、停電その他の不可抗力により、データの一部が消失・損壊した場合について、当社は何ら責任を負うものではありません。また、当社の判断により、バーチャル・ツアーの全部又は一部を消失させた場合でも、当社は何ら責任を負うものではありません。
          </span>

          <span style={style.article}>
            ９）お客さまが利用する端末の設定やインターネット環境、ブラウザの種類、またその他状態によって動作が遅くなったり、きちんと表示されない場合、もしくは本サービスを提供することができない場合があります。またお客さまが利用する端末の状態に限らず、本コンテンツの制作又は配信の環境状態によって、本サービスを提供することができない場合があります。
          </span>

          <span style={style.article}>
            １０）ツアー
            途中で誤った操作（リロード押すなど）で退出となった場合もツアーはそのまま進みます。あらかじめご了承ください。
          </span>

          <span style={style.article}>
            １１）ツアー終了後は、速やかに画面右下にある「退出」ボタンを押して退出お手続きください。
          </span>

          <span style={style.article}>
            １２）本コンテンツを商業目的で不特定多数の者に閲覧・利用させる行為や、ご参加者による映像の録画・撮影は、弊社コンテンツの権利侵害及びご参加者の権利侵害にあたりますので禁止とさせていただきます。
          </span>

          <span style={{ ...style.article, ...style.underLine }}>
            １３）アバター参加の登録
            名は、常識的な判断でのハンドル名（ニックネーム）でお願いします。（実名やメールアドレスなどの個人情報、皆さんが気分を害するような公序良俗に反するもの、差別用語などは禁止です。）
          </span>

          <label style={style.label}>
            <input
              style={style.input}
              type="checkbox"
              onChange={consent.handleChange}
              value={consent.value}
            />
            上記文を読んで理解し同意しました。
          </label>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleAgreeClick}
          color="primary"
          disabled={!(consent.value === 1)}>
          ツアーに参加する
        </Button>
        <Button onClick={handleDisagreeClick} color="primary">
          ツアーに参加しない
        </Button>
      </DialogActions>
    </Dialog>
  );
});

export default ConnectionConsent;
