import * as L from 'leaflet';
import Stroly from 'stroly-js';
import React from 'react';

import { Marker, Markers, LatLng, Landmark } from '../domains/map/map.model';
import { CentralMarkerService } from './central-marker.service';
import { LocationMarker } from '../domains/marker/location-marker';
import { LandmarkMarker } from '../domains/marker/landmark-marker';
import { PointMarker } from '../domains/marker/point-marker';
import { CommentMessage } from '../domains/ws/message.model';
import { Character } from '../domains/user/character';

import { setMetaData, setLandmarkContnet } from '../core/reducer';

declare module 'leaflet' {
  interface LeafletEvent {
    latlng: LatLng;
  }
}

export class MapService {
  stroly!: Stroly;
  strolyMap!: L.Map;
  private centerLatLng!: L.LatLng;
  private commentMarker!: L.Marker;
  private centralMarkerService!: CentralMarkerService;
  private readonly landmarks = new Map<number, any>([]);
  private readonly landmarkLayers = new L.LayerGroup();
  private selectedLandmarkId!: number | undefined;
  readonly locations: { [token: number]: L.Marker } = {};

  locationList: Markers = {};
  timer!: NodeJS.Timeout;

  constructor(private dispatch: React.Dispatch<any>) {}

  initStrolyMap(elem: any, token: number, mapID: string) {
    this.stroly = new Stroly(elem, mapID);
    this.strolyMap = this.stroly.map;
    if (L.Browser.mobile) {
      this.stroly.map.removeControl(this.stroly.map.zoomControl);
    }
    this.centralMarkerService = new CentralMarkerService(this.stroly);

    this.stroly.renderBaseLayer({
      onReady: () => {
        this.dispatch(setMetaData(this.stroly.getMapMetaData()));
        this.stroly.map.setZoom(5);
        this.landmarkLayers.addTo(this.strolyMap);
        this.centerLatLng = this.getCentralLatLng();
        this.emitCentralMove(token);

        this.strolyMap.on('moveend', () => {
          clearInterval(this.timer);
          this.centralMarkerService.fireCentralMove(token);

          const xy = this.strolyMap.getCenter();
          const latlng = this.stroly.strolyToLatLng(xy);
          const zoomScale = this.strolyMap.getZoom();
          const date = new Date().toISOString();
          gtag('event', 'move', {
            event_category: 'location',
            event_label: JSON.stringify([
              mapID,
              latlng?.lat,
              latlng?.lng,
              xy.lat,
              xy.lng,
              zoomScale,
              date,
            ]),
          });

          this.emitCentralMove(token);
        });
        this.initLandmark(mapID);
      },
    });
  }

  private emitCentralMove(token: number) {
    this.timer = setInterval(() => {
      this.centralMarkerService.fireCentralMove(token);
    }, 10000);
  }

  initLandmark(mapID: string) {
    const landmarkCategories = this.stroly.getLandmarkCategories();
    landmarkCategories.forEach((category) => {
      category.landmark.forEach((landmark: Landmark) => {
        const xy = {
          lat: landmark.x,
          lng: landmark.y,
        };

        const landmarkMarker = new LandmarkMarker(
          this.stroly.L,
          xy,
          category.icon,
        );
        landmarkMarker.marker.on('click', () => {
          this.dispatch(
            setLandmarkContnet({
              id: landmark.id,
              name: landmark.name,
              description: landmark.description,
              xy: {
                lat: landmark.x,
                lng: landmark.y,
              },
            }),
          );
          this.setSelectedLandmarkMarker(landmark.id);

          gtag('event', 'click', {
            event_category: 'landmark',
            event_label: JSON.stringify([mapID, landmark.id, landmark.name]),
          });
        });

        if (!this.landmarks.has(landmark.id)) {
          this.landmarks.set(landmark.id, {
            icon: category.icon,
            marker: landmarkMarker.marker,
          });
        }

        // if (!this.landmarkLayers.hasLayer(landmarkMarker.marker)) {
        //   this.landmarkLayers.addLayer(landmarkMarker.marker);
        // }
        // Todo 上記のようにLayerGroupにマーカーを登録すると
        // Todo なぜか全部のランドマークが表示されないので（1,2個表示されない）、
        // Todo 直接addToして地図に登録している
        landmarkMarker.marker.addTo(this.strolyMap);
      });
    });
  }

  setSelectedLandmarkMarker(landmarkId?: number) {
    if (this.selectedLandmarkId) {
      const secelctedLandmark = this.landmarks.get(this.selectedLandmarkId);
      if (secelctedLandmark) {
        const icon = LandmarkMarker.createIcon(
          secelctedLandmark.icon?.iconStyle?.icon,
        );
        secelctedLandmark.marker.setIcon(icon);
        this.selectedLandmarkId = undefined;
      }
    }

    if (landmarkId) {
      const landmark = this.landmarks.get(landmarkId);
      if (landmark) {
        const icon = LandmarkMarker.createIcon(
          landmark.icon?.iconStyle?.selectedIcon,
          true,
        );
        landmark.marker.setIcon(icon);
        this.selectedLandmarkId = landmarkId;
      }
    }
  }

  getCentralLatLng() {
    return this.strolyMap.getCenter();
  }

  getIsInMap(latlng: LatLng) {
    return this.stroly.getIsInMap(latlng);
  }

  createCentralMarker(
    token: number,
    colors: string[],
    character: Character,
  ): void {
    this.centralMarkerService.create(token, colors, character);
  }

  moveCentralMarker(marker: Marker): void {
    this.centralMarkerService.move(marker);
  }

  panToCentralMarker(token: number) {
    this.centralMarkerService.panTo(token);
  }

  removeCentralMarker(marker: Marker): void {
    this.centralMarkerService.remove(marker.token);
  }

  setCommentToCentralMarker(message: CommentMessage) {
    this.centralMarkerService.setComment(message);
  }

  putCommentMarker(message: CommentMessage): void {
    if (this.strolyMap.hasLayer(this.commentMarker)) {
      this.strolyMap.removeLayer(this.commentMarker);
    }
    this.commentMarker = new PointMarker(
      { lat: message.latlng.lat, lng: message.latlng.lng },
      message.color,
    );
    this.commentMarker.addTo(this.strolyMap);
  }

  getCurrentLocation() {
    if (this.strolyMap && this.strolyMap.locate) {
      this.strolyMap.locate({
        watch: true,
        enableHighAccuracy: true,
      });
    }
  }

  stopGettingCurrentLocation() {
    this.strolyMap.stopLocate();
    this.strolyMap.fire('locationstop');
  }

  putLocationMarker(marker: Marker) {
    if (this.locations[marker.token]) {
      this.locations[marker.token].setLatLng([marker.lat, marker.lng]);
      return;
    }

    this.locations[marker.token] = new LocationMarker(
      { lat: marker.lat, lng: marker.lng },
      marker.color,
      new Character(marker.name, marker.characterType),
    ).addTo(this.strolyMap);
  }

  getLocationMarkerLatLng(token: number) {
    return this.locations[token].getLatLng();
  }

  panToLocationMarker(token: number) {
    const locationMarker = this.locations[token];
    if (locationMarker) {
      this.strolyMap.panTo(locationMarker.getLatLng());
    }
  }

  setCommentToLocationMarker(message: CommentMessage) {
    const locationMarker = this.locations[message.token];
    if (locationMarker) {
      locationMarker.setIcon(
        LocationMarker.createIcon(
          message.color,
          new Character(message.name, message.characterType),
          message.comment,
        ),
      );
    }
  }

  removeLocationMarker(token: number) {
    if (this.locations[token]) {
      this.strolyMap.removeLayer(this.locations[token]);
      delete this.locations[token];
    }
  }

  panTo(lat: number, lng: number) {
    this.strolyMap.panTo(new L.LatLng(lat, lng));
  }

  panToCenter() {
    this.panTo(this.centerLatLng.lat, this.centerLatLng.lng);
  }
}
