import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import DirectionsWalkIcon from '@material-ui/icons/DirectionsWalk';
import MessageIcon from '@material-ui/icons/Message';
import CloseIcon from '@material-ui/icons/Close';
import Drawer from '@material-ui/core/Drawer';

import './styles/landmark.scss';
import { UserType } from '../../domains/user/user.model';
import { LatLng, LandmarkContentLocalized } from '../../domains/map/map.model';

type Props = {
  landmark: LandmarkContentLocalized;
  userType: UserType;
  isLandmarkCommentEnabled: boolean;
  handleSendLandmark: (landmark: {
    name: string | undefined;
    xy: LatLng;
  }) => void;
  panToLandmark: (latlng: LatLng) => void;
  setSelectedLandmarkMarker: (landmarkId?: number) => void;
};

const Landmark = React.memo((props: Props) => {
  const style: { [key: string]: React.CSSProperties } = {
    drawer: {
      zIndex: 1001,
    },
    div: {
      padding: '16px 16px 0',
      borderBottom: '1px solid #bdbdbd',
    },
    name: {
      margin: 0,
      paddingBottom: '16px',
      fontSize: '18px',
    },
    buttons: {
      display: 'flex',
      margin: 0,
      padding: 0,
      justifyContent: 'space-between',
    },
    button: {
      display: 'inline-block',
      textAlign: 'center',
      width: 'calc(100% /3)',
      cursor: 'pointer',
      margin: 0,
      padding: '0 0 8px',
      color: '#424242',
      fontSize: '10px',
    },
    iframe: {
      margin: 0,
      padding: 0,
      border: 0,
      width: '100%',
      height: 'calc(100% - 104px - 16px)',
      boxSizing: 'border-box',
    },
  };

  const [isLandmarkOpen, setIsLandmarkOpen] = React.useState(false);
  const [isEnabled, setIsEnabled] = React.useState(true);
  const [srcDoc, setSrcDoc] = React.useState('');

  React.useEffect(() => {
    if (!props.landmark) {
      return;
    }
    const description = props.landmark.description;
    const content = description
      ? description.replace(/='\./gi, "='https://ods3.illustmap.org")
      : '';
    setSrcDoc(content);

    if (!isLandmarkOpen) {
      setIsLandmarkOpen(true);
    }
  }, [props.landmark]);

  const handleCommentClick = useCallback(() => {
    if (isEnabled) {
      props.handleSendLandmark({
        name: props.landmark.name,
        xy: props.landmark.xy,
      });
      setIsEnabled(false);

      setTimeout(() => {
        setIsEnabled(true);
      }, 1000);
    }
  }, [isEnabled, props]);

  const handleMovieClick = useCallback(() => {
    props.panToLandmark(props.landmark.xy);
  }, [props]);

  const handleCloseClick = useCallback(() => {
    if (isLandmarkOpen) {
      props.setSelectedLandmarkMarker(props.landmark.id);
      setSrcDoc('');
      setIsLandmarkOpen(false);
    }
  }, [isLandmarkOpen, props]);

  return (
    <Drawer
      className="landmark"
      style={style.drawer}
      anchor="left"
      open={isLandmarkOpen}
      ModalProps={{
        disablePortal: true,
        disableEnforceFocus: true,
      }}>
      <div style={style.div}>
        <h2 style={style.name}>
          <LocationOnIcon style={{ verticalAlign: 'top' }} />
          {props.landmark.name}
        </h2>
        <div style={style.buttons}>
          <div style={style.button} onClick={handleMovieClick}>
            <DirectionsWalkIcon />
            <br />
            <FormattedMessage id="Landmark.Move" />
          </div>
          {props.userType === UserType.Guide ||
          (props.userType !== UserType.Browse &&
            props.isLandmarkCommentEnabled) ? (
            <div style={style.button} onClick={handleCommentClick}>
              <MessageIcon />
              <br />
              <FormattedMessage id="Landmark.Comment" />
            </div>
          ) : null}

          <div style={style.button} onClick={handleCloseClick}>
            <CloseIcon />
            <br />
            <FormattedMessage id="Landmark.Close" />
          </div>
        </div>
      </div>

      <iframe title="landmark" srcDoc={srcDoc} style={style.iframe}></iframe>
    </Drawer>
  );
});

export default Landmark;
