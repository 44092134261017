import React from 'react';
import GpsNotFixedIcon from '@material-ui/icons/GpsNotFixed';

type Props = {};

const CentralIcon = React.memo((props: Props) => {
  const style: { [key: string]: React.CSSProperties } = {
    icon: {
      filter: 'drop-shadow(0px 0px 2px #000)',
      color: '#eee',
      position: 'absolute',
      zIndex: 999,
      top: 'calc(50% - 20px)',
      left: 'calc(50% - 20px)',
      fontSize: 40,
    },
  };

  return <GpsNotFixedIcon style={style.icon} />;
});

export default CentralIcon;
