import React, { useCallback, Dispatch, SetStateAction } from 'react';
import { injectIntl, IntlShape } from 'react-intl';
import InputBase from '@material-ui/core/InputBase';
import SendIcon from '@material-ui/icons/Send';

type Props = {
  intl: IntlShape;
  comment: string;
  isDisabled: boolean;
  handleChange(value: string): void;
  handleSendMessage(): void;
  setIsKeybordOpen: Dispatch<SetStateAction<boolean>>;
};

const CommentSendMessage = React.memo((props: Props) => {
  const style: { [key: string]: React.CSSProperties } = {
    button: {
      boxSizing: 'border-box',
      color: '#e6b336',
      backgroundColor: '#fff',
      border: 0,
      marginLeft: '4px',
      padding: 0,
      cursor: 'pointer',
    },
    disabled: {
      opacity: '0.8',
      cursor: 'not-allowed',
    },
  };

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      props.handleChange(event.currentTarget.value);
    },
    [props],
  );

  const [isEnabled, setIsEnabled] = React.useState(true);
  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      if (event.keyCode === 13) {
        if (isEnabled) {
          props.handleSendMessage();
          setIsEnabled(false);

          setTimeout(() => {
            setIsEnabled(true);
          }, 1000);
        }
      }
    },
    [isEnabled, props],
  );

  const handleClick = useCallback(() => {
    if (isEnabled && props.comment) {
      props.handleSendMessage();
      props.setIsKeybordOpen(false);
      setIsEnabled(false);

      setTimeout(() => {
        setIsEnabled(true);
      }, 1000);
    }
  }, [isEnabled, props]);

  const placeholder = props.intl.formatMessage({
    id: 'CommentSendMessage.Placeholder',
  });

  return (
    <>
      <InputBase
        type="text"
        name="comment"
        placeholder={placeholder}
        inputProps={{ 'aria-label': 'Message' }}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        value={props.comment}
      />
      <button
        aria-label="Send"
        style={
          props.isDisabled
            ? { ...style.button, ...style.disabled }
            : style.button
        }
        onClick={handleClick}
        disabled={props.isDisabled}>
        <SendIcon fontSize="small" />
      </button>
    </>
  );
});

export default injectIntl(CommentSendMessage);
