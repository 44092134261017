import * as L from 'leaflet';
import Stroly from 'stroly-js';

import { CommentMessage } from '../domains/ws/message.model';
import { Character } from '../domains/user/character';
import { LatLng, Marker } from '../domains/map/map.model';

import { CentralMarkerFactory } from '../domains/marker/central-marker-factory';
import { CentralMarkers } from '../domains/marker/central-markers';

export class CentralMarkerService {
  private readonly centralMarkerFactory: CentralMarkerFactory;
  private readonly centralMarkers: CentralMarkers = new CentralMarkers();
  private readonly centralMarkersGroup = new L.LayerGroup();

  constructor(private stroly: Stroly) {
    this.centralMarkersGroup.addTo(stroly.map);
    this.centralMarkerFactory = new CentralMarkerFactory();
  }

  create(token: number, colors: string[], character: Character) {
    const centralMarker = this.centralMarkers.get(token);

    if (centralMarker) {
      return;
    }
    this.put(token, this.stroly.map.getCenter(), character, colors);
    this.fireCentralMove(token);
  }

  move(marker: Marker) {
    const centralMarker = this.centralMarkers.get(marker.token);

    if (centralMarker) {
      centralMarker.setLatLng([marker.lat, marker.lng]);
      return;
    }

    this.put(
      marker.token,
      { lat: marker.lat, lng: marker.lng },
      new Character(marker.name, marker.characterType),
      marker.color,
    );
  }

  panTo(token: number) {
    const centralMarker = this.centralMarkers.get(token);
    if (centralMarker) {
      this.stroly.map.panTo(centralMarker.getLatLng());
    }
  }

  remove(token: number) {
    const centralMarker = this.centralMarkers.get(token);
    if (centralMarker) {
      this.stroly.map.removeLayer(centralMarker);
      this.centralMarkers.delete(token);
      this.centralMarkersGroup.removeLayer(centralMarker);
    }
  }

  setComment(message: CommentMessage) {
    const centralMarker = this.centralMarkers.get(message.token);
    if (centralMarker) {
      const CentralMarker = this.centralMarkerFactory.centralMarkerByName(
        message.characterType,
      );

      centralMarker.setIcon(
        CentralMarker.createIcon(
          new Character(message.name, message.characterType),
          message.color,
          message.comment,
        ),
      );
    }
  }

  fireCentralMove(token: number) {
    const centralMarker = this.centralMarkers.get(token);
    if (centralMarker) {
      const center = this.stroly.map.getCenter();
      centralMarker.setLatLng(center);
      this.stroly.map.fire('centralmove', center);
    }
  }

  private put(
    token: number,
    latlng: LatLng,
    character: Character,
    colors: string[],
  ) {
    const zoomSize = this.stroly.map.getZoom();
    const CentralMarker = this.centralMarkerFactory.centralMarkerByName(
      character.getCharacterType(),
    );
    const newCentralMarker = new CentralMarker(
      this.stroly.L,
      latlng,
      character,
      colors,
      zoomSize,
    ).marker.addEventListener('contextmenu', () => {
      this.stroly.map.fire('deportation', { token });
    });

    this.centralMarkersGroup.addLayer(newCentralMarker);
    this.centralMarkers.set(token, newCentralMarker);
  }
}
