import React from 'react';
import { FormattedMessage } from 'react-intl';
import GpsFixed from '@material-ui/icons/GpsFixed';

type Props = {
  isDisabled: boolean;
  isSharing: boolean;
  controlLocate: () => void;
};

const LocateButton = React.memo((props: Props) => {
  const style: { [key: string]: React.CSSProperties } = {
    button: {
      width: 'calc(100% - 8px)',
      boxSizing: 'border-box',
      padding: '8px 0',
      margin: '4px 4px 0',
      border: 0,
      borderRadius: '4px',
      backgroundColor: '#fff',
      cursor: 'pointer',
      fontSize: '10px',
    },
  };

  const { isDisabled, isSharing, controlLocate } = props;

  const handleClick = React.useCallback(() => {
    controlLocate();
  }, [controlLocate]);

  const button = isSharing ? (
    <button onClick={handleClick} style={style.button} disabled={isDisabled}>
      <GpsFixed />
      <br />
      <FormattedMessage id="LocateButton.Stop" />
    </button>
  ) : (
    <button onClick={handleClick} style={style.button} disabled={isDisabled}>
      <GpsFixed />
      <br />
      <FormattedMessage id="LocateButton.Share" />
    </button>
  );

  return button;
});

export default LocateButton;
