import React from 'react';
import MuiSnackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';

type Props = {
  isSnackbarOpen: boolean;
  setIsSnackbarOpen: React.Dispatch<React.SetStateAction<boolean>>;
  children?: React.ReactNode;
};

const Snackbar = (props: Props) => {
  function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const handleClose = React.useCallback(
    (event?: React.SyntheticEvent, reason?: string) => {
      if (reason === 'clickaway') {
        return;
      }
      props.setIsSnackbarOpen(false);
    },
    [props],
  );

  return (
    <MuiSnackbar
      open={props.isSnackbarOpen}
      autoHideDuration={2000}
      onClose={handleClose}
      anchorOrigin={{
        horizontal: 'right',
        vertical: 'top',
      }}>
      <Alert onClose={handleClose} severity="info">
        {props.children}
      </Alert>
    </MuiSnackbar>
  );
};

export default Snackbar;
