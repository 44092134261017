import React from 'react';
import { FormattedMessage } from 'react-intl';

import { UserType } from '../../domains/user/user.model';

type Props = {
  name: string;
  userType: UserType;
  isError: boolean;
  isGuideMode: boolean;
  handleChange: (value: string) => void;
};

const ConnectionNameForm = React.memo((props: Props) => {
  const style: { [key: string]: React.CSSProperties } = {
    div: {
      marginBottom: '32px',
    },
    text: {
      fontWeight: 'bold',
      marginBottom: '4px',
    },
    input: {
      color: '#424242',
      padding: '0 8px',
      width: '200px',
      border: '1px solid #424242',
      height: '32px',
      fontSize: '16px',
    },
    span: {
      marginTop: '8px',
      color: 'rgba(229,57,53 ,1)',
      display: 'block',
      fontSize: '14px',
    },
  };

  const handleChange = React.useCallback(
    (event: React.FormEvent<HTMLInputElement>) => {
      props.handleChange(event.currentTarget.value);
    },
    [props],
  );

  // todo　映画村案件が終わったら、以下の2バイト文字判定の部分消す
  return (
    <div style={style.div}>
      <p style={style.text}>
        <FormattedMessage id="ConnectionNameForm.Nickname" />
      </p>
      {props.isGuideMode && props.userType !== UserType.Guide ? (
        <input
          maxLength={6}
          placeholder="6文字以内の半角英数字"
          type="text"
          value={props.name}
          onChange={(event) => handleChange(event)}
          style={style.input}
        />
      ) : (
        <input
          maxLength={16}
          type="text"
          value={props.name}
          onChange={(event) => handleChange(event)}
          style={style.input}
        />
      )}

      {props.isError ? (
        <span style={style.span}>
          <FormattedMessage id="ConnectionNameForm.ErrorMessage" />
        </span>
      ) : (
        <span style={style.span}></span>
      )}
    </div>
  );
});

export default ConnectionNameForm;
