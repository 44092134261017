import React from 'react';
import { FormattedMessage } from 'react-intl';

type Props = {
  handleClick: () => void;
  isDisabled: boolean;
};

const ConnectionButton = React.memo((props: Props) => {
  const style: { [key: string]: React.CSSProperties } = {
    button: {
      cursor: 'pointer',
      width: '128px',
      lineHeight: '32px',
      borderRadius: '25px',
      border: 0,
      boxShadow: '0 1px 2px #212121',
      fontSize: '16px',
      backgroundColor: '#fbb63f',
      color: '#424242',
      fontWeight: 'bold',
    },
  };

  const handleClick = React.useCallback(() => {
    props.handleClick();
  }, [props]);

  return (
    <button
      style={style.button}
      onClick={handleClick}
      disabled={props.isDisabled}>
      <FormattedMessage id="ConnectionButton.Start" />
    </button>
  );
});

export default ConnectionButton;
