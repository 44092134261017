import React from 'react';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { FormattedMessage } from 'react-intl';

type Props = {
  handleExitButtonClick(): void;
};

const ExitButton = React.memo((props: Props) => {
  const style: { [key: string]: React.CSSProperties } = {
    exit: {
      textAlign: 'center',
      boxSizing: 'border-box',
      padding: '8px 0',
      margin: '4px 4px 0',
      display: 'block',
      border: 0,
      color: '#fff',
      backgroundColor: 'rgba(96,125,139 ,1)',
      borderRadius: '4px',
      fontWeight: 'bold',
      cursor: 'pointer',
      fontSize: '10px',
      width: '40px',
    },
  };

  return (
    <button onClick={props.handleExitButtonClick} style={style.exit}>
      <ExitToAppIcon />
      <br />
      <FormattedMessage id="ExitButton.Exit" />
    </button>
  );
});

export default ExitButton;
