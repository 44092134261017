import React from 'react';
import { CharacterType } from '../../domains/user/character';

type Props = {
  handleCharacterSelected: (characterType: CharacterType) => void;
};

const ConnectionGuideImage = React.memo((props: Props) => {
  const style: { [key: string]: React.CSSProperties } = {
    selected: {
      cursor: 'pointer',
      padding: '4px',
      border: '3px solid #696969',
    },
    text: {
      fontWeight: 'bold',
      marginBottom: '8px',
    },
    nonSelected: {
      cursor: 'pointer',
      padding: '7px',
    },
  };

  React.useEffect(() => {
    props.handleCharacterSelected(CharacterType.Guide);
  }, []);

  return (
    <div>
      <div>
        <img
          src="/images/guide.png"
          alt="Guide"
          width="84"
          style={style.selected}
        />
      </div>
    </div>
  );
});

export default ConnectionGuideImage;
