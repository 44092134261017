import React from 'react';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';

import { UserType } from '../../domains/user/user.model';
import * as Constants from '../../core/constants';

import ConnectionBeforeTour from './ConnectionBeforeTour';
import ConnectionConsent from './ConnectionConsent';

type Props = {
  isGuideMode: boolean;
  userType: UserType;
  title: string | undefined;
  isBeforeTour: boolean;
  startDate: moment.Moment | undefined;
  isFull: boolean;
  children: any;
};

const ConnectionModal = React.memo((props: Props) => {
  const style: { [key: string]: React.CSSProperties } = {
    modal: {
      margin: '16px auto',
      textAlign: 'center',
      boxSizing: 'border-box',
      borderRadius: '8px',
      minWidth: '320px',
      width: '100%',
      color: '#212121',
      padding: '16px',
      maxHeight: '100%',
      overflowY: 'scroll',
    },
    a: {
      color: '#424242',
      textDecoration: 'none',
    },
    title: {
      margin: '0 auto 16px',
      fontWeight: 'bold',
      fontSize: '24px',
      lineHeight: 1,
    },
    mapTitle: {
      margin: '0 auto 32px',
      fontWeight: 'bold',
      fontSize: '24px',
      lineHeight: 1.3,
      color: '#424242',
    },
    border: {
      borderRadius: '8px',
      marginBottom: '32px',
    },
  };

  const [title, setTitle] = React.useState(Constants.Title);
  React.useEffect(() => {
    if (props.isGuideMode) {
      setTitle(Constants.TourTitle);
    }
  }, [props.isGuideMode]);

  return (
    <div style={style.modal}>
      {props.isGuideMode && props.userType !== UserType.Guide ? (
        <ConnectionConsent />
      ) : null}
      <h2 style={style.title}>
        <a
          href="https://stroly.com/"
          target="_blank"
          rel="noopener noreferrer"
          style={style.a}>
          <img
            src="/images/stroly_lab.png"
            alt="Stroly LAB"
            width="88"
            style={{ verticalAlign: 'middle' }}
          />
          {title}
        </a>
        <br />
        {props.userType === UserType.Guide ? 'for Guide' : null}
      </h2>
      <h3 style={style.mapTitle}>{props.title}</h3>
      {props.isBeforeTour && props.userType !== UserType.Guide ? (
        <ConnectionBeforeTour startDate={props.startDate} />
      ) : props.isFull && props.userType !== UserType.Guide ? (
        <p>
          <FormattedMessage id="ConnectionModal.Message1" />
          <br />
          <FormattedMessage id="ConnectionModal.Message2" />
        </p>
      ) : (
        <>
          <div style={style.border}>
            {props.children[0]}
            {props.children[1]}
          </div>
          {props.children[2]}
          {props.children[3]}
        </>
      )}
      {props.children[4]}
      {props.isBeforeTour && props.userType !== UserType.Guide
        ? null
        : props.children[5]}
    </div>
  );
});

export default ConnectionModal;
