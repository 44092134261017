import React from 'react';
import { FormattedMessage } from 'react-intl';

type Props = {
  participants: number;
  limit: number;
};

const ConnectionParticipants = React.memo((props: Props) => {
  const style: { [key: string]: React.CSSProperties } = {
    participants: {
      fontSize: '20px',
      fontWeight: 'bold',
    },
  };

  const participants = props.participants ? (
    <div>
      <p>
        <FormattedMessage id="ConnectionParticipants.Current" />{' '}
        <span style={style.participants}>{props.participants}</span>{' '}
        <FormattedMessage id="ConnectionParticipants.PeopleJA" />
        <br />
        <FormattedMessage
          id="ConnectionParticipants.Maximum"
          values={{
            limit: props.limit,
          }}
        />
      </p>
    </div>
  ) : null;

  return participants;
});

export default ConnectionParticipants;
