import React, { useCallback } from 'react';
import { injectIntl, IntlShape } from 'react-intl';
import MessageIcon from '@material-ui/icons/Message';

type Props = {
  intl: IntlShape;
  isChatOpen: boolean;
  handleChatButtonClick: () => void;
};

const CommentButton = React.memo((props: Props) => {
  const style: { [key: string]: React.CSSProperties } = {
    p: {
      color: '#424242',
      width: '64px',
      height: '40px',
      margin: '8px 0 0',
      padding: 0,
      textAlign: 'center',
      fontSize: '10px',
      fontWeight: 'bold',
      cursor: 'pointer',
    },
  };

  const open = props.intl.formatMessage({ id: 'CommentButton.Open' });
  const close = props.intl.formatMessage({ id: 'CommentButton.Close' });

  const ChatButtonText = useCallback(() => {
    return props.isChatOpen ? close : open;
  }, [props.isChatOpen]);

  const handleClick = useCallback(() => {
    gtag('event', 'click', {
      event_category: 'button',
      event_label: props.isChatOpen ? 'Close Comments' : 'Open Comments',
    });

    props.handleChatButtonClick();
  }, [props]);

  return (
    <p style={style.p} onClick={handleClick}>
      <MessageIcon fontSize="small" style={{ verticalAlign: 'middle' }} />
      <br />
      {ChatButtonText()}
    </p>
  );
});

export default injectIntl(CommentButton);
