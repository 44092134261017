import React from 'react';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';

import { GuideOptions } from '../../containers/AppContainer';

type Props = {
  startDate: moment.Moment | undefined;
  guideOptions: GuideOptions;
};

const ConnectionStartDate = React.memo((props: Props) => {
  const style: { [key: string]: React.CSSProperties } = {
    text: {
      fontWeight: 'bold',
      marginBottom: '0',
    },
    date: {
      margin: '0 0 16px',
    },
  };

  const startDate =
    props.guideOptions.isGuideMode && props.startDate ? (
      <div>
        <p style={style.text}>
          <FormattedMessage id="ConnectionStartDate.DateAndTime" />
        </p>
        <p style={style.date}>{props.startDate.format('YYYY-MM-DD HH:mm')}</p>
      </div>
    ) : null;

  return startDate;
});

export default ConnectionStartDate;
