import React, { useState, useEffect } from 'react';
import { injectIntl, IntlShape } from 'react-intl';
import moment from 'moment';

import { useWS } from './hooks/useWS';
import { CommentMessage } from '../domains/ws/message.model';
import { EmojiName, Emoji } from '../domains/ws/emoji';
import { LatLng, LandmarkContentLocalized } from '../domains/map/map.model';
import { User } from '../domains/user/user.model';
import { GuideOptions } from './AppContainer';
import { CharacterType } from '../domains/user/character';
import { MapService } from '../domains/map';

import Landmark from '../components/comment/Landmark';
import CommentForm from '../components/comment/CommentForm';
import CommentList from '../components/comment/CommentList';

type Props = {
  intl: IntlShape;
  user: User;
  name: string;
  characterType: CharacterType;
  guideOptions: GuideOptions;
  isLogedIn: boolean;
  isChatOpen: boolean;
  isShareMode: boolean;
  protocol: string;
  comments: CommentMessage[];
  landmark: LandmarkContentLocalized;
  mapService: MapService;
  setCommentToCentralMarker(message: CommentMessage): void;
  setSelectedLandmarkMarker(): void;
  panToLandmark(latlng: LatLng): void;
  panToComment(message: CommentMessage): void;
  handleChatButtonClick(): void;
  dispatchUpdateComments(omment: CommentMessage): void;
};

const CommentContainer = (props: Props) => {
  const [comment, setComment] = useState<string>('');
  function handleChange(comment: string) {
    setComment(comment);
  }

  const [isDisabled, setIsDisabled] = useState<boolean>(true);

  /**
   * WebSocketの接続が開始された時の処理
   */
  function handleWSOpen() {
    setIsDisabled(false);
    handleSendMessage();
  }
  /**
   * WebSocketの接続が切れた時の処理
   */
  function handleWSClose(event: CloseEvent) {
    console.log(
      'Socket for comment is closed. Reconnect will be attempted in 2 second.',
      event.reason,
    );
    setTimeout(() => {
      commentWS.connect();
    }, 2000);
  }
  /**
   * WebSocketサーバーからメッセージを受け取った時の処理
   */
  function handleWSMessage(event: MessageEvent) {
    const commentMessage: CommentMessage = JSON.parse(event.data);
    props.dispatchUpdateComments(commentMessage);
    props.setCommentToCentralMarker(commentMessage);

    if (props.user.token === commentMessage.token) {
      setComment('');
    }
  }
  const commentWS = useWS<CommentMessage>(
    `${process.env.REACT_APP_COMMENT_SHARING_SERVER}/comment`,
    props.protocol,
    {
      handleWSOpen,
      handleWSClose,
      handleWSMessage,
    },
  );

  useEffect(() => {
    if (!commentWS.get() && props.isLogedIn) {
      commentWS.connect();
    }
  }, [props.isLogedIn]);

  function handleSendButtonClick(comment: string | EmojiName) {
    const centerLatLng = props.isShareMode
      ? props.mapService.getLocationMarkerLatLng(props.user.token)
      : props.mapService.getCentralLatLng();
    const date = moment().format('YYYY/MM/DD HH:mm:ss');
    const id = date + Math.random() + props.user.token;

    const commentMessage: CommentMessage = {
      id,
      date,
      token: props.user.token,
      color: props.user.color,
      name: props.name,
      characterType: props.characterType,
      latlng: centerLatLng,
      comment,
      type: Emoji.isEmoji(comment) ? 'emoji' : 'comment',
      room: props.protocol,
    };
    commentWS.send(commentMessage);

    const chatType = Emoji.isEmoji(comment)
      ? new Emoji(comment).getEmojiName()
      : 'comment';

    gtag('event', 'click', {
      event_category: 'chat',
      event_label: chatType,
    });
  }

  function handleLandmarkCommentClick(landmark: {
    name: string | undefined;
    xy: LatLng;
  }) {
    const date = moment().format('YYYY/MM/DD HH:mm:ss');
    const id = date + Math.random() + props.user.token;

    const message = props.intl.formatMessage({id: 'CommentConatainer.CheckLandmark'}, {landmark: props.landmark.name});
    const commentMessage: CommentMessage = {
      id,
      date,
      token: props.user.token,
      color: props.user.color,
      name: props.name,
      characterType: props.characterType,
      latlng: landmark.xy,
      comment: message,
      type: 'landmark',
      room: props.protocol,
    };
    commentWS.send(commentMessage);

    gtag('event', 'click', {
      event_category: 'chat',
      event_label: 'landmark',
    });
  }

  function handleSendMessage() {
    if (!comment) {
      return;
    }
    handleSendButtonClick(comment);
  }

  function handleSendEmoji(emoji: EmojiName) {
    handleSendButtonClick(emoji);
  }

  return (
    <>
      <Landmark
        userType={props.user.type}
        isLandmarkCommentEnabled={props.guideOptions?.isLandmarkCommentEnabled}
        setSelectedLandmarkMarker={props.setSelectedLandmarkMarker}
        panToLandmark={props.panToLandmark}
        landmark={props.landmark}
        handleSendLandmark={handleLandmarkCommentClick}
      />
      <CommentList
        guideOptions={props.guideOptions}
        isChatOpen={props.isChatOpen}
        comments={props.comments}
        panToComment={props.panToComment}
      />
      <CommentForm
        userType={props.user.type}
        guideOptions={props.guideOptions}
        comment={comment}
        isChatOpen={props.isChatOpen}
        isDisabled={isDisabled}
        handleChange={handleChange}
        handleSendMessage={handleSendMessage}
        handleSendEmoji={handleSendEmoji}
        handleChatButtonClick={props.handleChatButtonClick}
      />
    </>
  );
};

export default injectIntl(CommentContainer);
