import * as L from 'leaflet';

import { LatLng } from '../map/map.model';
import { Character } from '../user/character';

export class CentralGuideMarker {
  readonly marker: L.Marker;

  constructor(
    L: any,
    latlng: LatLng,
    character: Character,
    color: string[],
    zoomSize: number,
  ) {
    this.marker = new L.marker([latlng.lat, latlng.lng], {
      icon: CentralGuideMarker.createIcon(character, color),
      draggable: false,
      // riseOnHover: true,
      // riseOffset: -2000,
      zIndexOffset: 2000,
    });
  }

  static getIsEmoji(message: string | undefined): boolean {
    if (!message) {
      return false;
    }
    return /^(👍|❤️|❗️)$/.test(message);
  }

  static createIcon(
    character: Character,
    color: string[],
    message?: string,
    zoomSize?: number,
  ): L.DivIcon {
    const MerkerWidth = 160;

    // const a = [(26 / 3) * 5, (100 / 3) * 5] as any;
    // let iconAnchor = [(a[0] / 5) * 3, (a[1] / 5) * 3] as any;
    const iconSize = character.getIconSize(character.getCharacterType());
    let iconAnchor = [MerkerWidth / 2, iconSize[1]] as any;

    // zoomサイズに合わせてアイコンのサイズを変更する処理
    // とりあえず挙動がおかしいということなので一時的にコメントアウトしておく
    // あとで調整する
    // if (zoomSize >= 5) {
    //   iconAnchor = [(a[0] / 5) * 5, (a[1] / 5) * 5];
    // }
    // if (zoomSize === 4) {
    //   iconAnchor = [(a[0] / 5) * 4, (a[1] / 5) * 4];
    // }
    // if (zoomSize === 3) {
    //   iconAnchor = [(a[0] / 5) * 3, (a[1] / 5) * 3];
    // }
    // if (zoomSize === 2) {
    //   iconAnchor = [(a[0] / 5) * 2, (a[1] / 5) * 2];
    // }
    // if (zoomSize <= 1) {
    //   iconAnchor = [(a[0] / 5) * 1, (a[1] / 5) * 1];
    // }

    const style = {
      marker: `
        position: relative;
        margin: 0;
        padding: 0;
        width: ${MerkerWidth}px;
        text-align: center;
      `,
      popup: `
        position: fixed;
        bottom: 20px;
        width: ${MerkerWidth}px;
        text-aling: center;
        filter: drop-shadow(0px 2px 4px #424242);
      `,
      comment: `
        display: inline-block;
        text-align: left;
        border-radius: 8px;
        background-color: #fff;
        color: #000;
        padding: 4px 12px;
        opacity: 1;
        max-width: ${MerkerWidth}px;
        word-wrap: break-word;
        hyphens: auto;
        font-size: 16px;
      `,
      message: `
        font-size: 26px;
      `,
      bubble: `
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -6px;
        border: 6px solid transparent;
        opacity: 1;
        border-top: 6px solid #fff;
      `,
      name: `
        border-radius: 4px;
        margin: 0;
        padding: 2px 16px;
        text-align: center;
        color: #fff;
        width: 100%;
        background-color: ${color[0]};
        box-shadow: 0 2px 4px #424242;
        font-size: 14px;
      `,
      image: `
        filter: drop-shadow(0px 2px 2px #424242);
      `,
    };

    const comment = CentralGuideMarker.getIsEmoji(message)
      ? `<span style="${style.message}">${message}</span>`
      : CentralGuideMarker.encodeMessage(message);

    const popup = message
      ? `<div style="${style.popup}">
          <div style="${style.comment}" lang="en">
            <b>${comment}</b>
          </div>
          <div style="${style.bubble}"></div>
        </div>`
      : '';

    const className =
      L.Browser.mobileWebkit &&
      typeof DeviceOrientationEvent.requestPermission !== 'function'
        ? ''
        : `marker-icon marker-icon-${character.getCharacterType()}`;

    return L.divIcon({
      className,
      iconAnchor,
      popupAnchor: [8, -104],
      html: `
        <div style="${style.marker}">
          ${popup}
          <div>
            <img src="/images/${character.getCharacterType()}.png" height="${
        iconSize[1]
      }" style="${style.image}" />
          <div>
          <span style="${
            style.name
          }"><b>ガイド: ${CentralGuideMarker.encodeMessage(
        character.getName(),
      )}</b></span>
        <div>
      `,
    });
  }

  static encodeMessage(string: String | undefined) {
    if (!string) {
      return string;
    }
    return string
      .replace(/&/g, '&amp;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
      .replace(/"/g, '&quot;')
      .replace(/'/g, '&#39;');
  }
}
