import React from 'react';

import { CommentMessage } from '../../domains/ws/message.model';
import { GuideOptions } from '../../containers/AppContainer';
import './styles/comment.scss';

import Drawer from '@material-ui/core/Drawer';

type Props = {
  isChatOpen: boolean;
  comments: CommentMessage[];
  guideOptions: GuideOptions;
  panToComment: (comment: CommentMessage) => void;
};

const CommentList = (props: Props) => {
  const style: { [key: string]: React.CSSProperties } = {
    drawer: {
      zIndex: 1000,
      right: '48px',
      bottom: 'auto',
      left: 'auto',
      top: 0,
    },
    ul: {
      margin: 0,
      listStyleType: 'none',
      padding: 0,
      color: '#fff',
      width: '240px',
    },
    li: {
      padding: '4px 8px',
      fontSize: '14px',
      lineHeight: '14px',
      borderBottom: '1px solid #424242',
      wordWrap: 'break-word',
      hyphens: 'auto',
    },
    name: {
      fontWeight: 'bold',
      fontSize: '14px',
      color: '#bdbdbd',
    },
    comment: {
      margin: 0,
      paddingLeft: '18px',
    },
    date: {
      color: '#9e9e9e',
      fontSize: '10px',
      lineHeight: '10px',
    } as React.CSSProperties,
  };

  const circleStyle = React.useCallback((comment: CommentMessage) => {
    return {
      display: 'inline-block',
      width: '14px',
      height: '14px',
      borderRadius: '50%',
      backgroundColor: comment.color[0],
      margin: '4px',
      marginLeft: 0,
      verticalAlign: 'middle',
      backgroundImage: `url('/images/${comment.characterType}.png')`,
      backgroundSize: 'cover',
    } as React.CSSProperties;
  }, []);

  const ref = React.useRef<HTMLDivElement>(null);
  const scrollToBottomOfList = React.useCallback(() => {
    ref!.current!.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
    });
  }, [ref]);

  React.useEffect(() => {
    if (props.isChatOpen) {
      setTimeout(() => {
        scrollToBottomOfList();
      }, 400);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.comments, props.isChatOpen]);

  const [selected, setSelected] = React.useState<string>();
  const handleClick = React.useCallback(
    (comment: CommentMessage, key: string) => {
      setSelected(key);
      props.panToComment(comment);
    },
    [props],
  );

  const comments = props.comments
    .filter((comment: CommentMessage) => {
      return (
        props.guideOptions.isCommentListEnabled ||
        (!props.guideOptions.isCommentListEnabled &&
          props.guideOptions.guideToken === comment.token)
      );
    })
    .map((comment: CommentMessage) => {
      return (
        <li
          key={comment.id}
          style={style.li}
          onClick={() => handleClick(comment, comment.id)}
          className={selected === comment.id ? 'selected' : 'non-selected'}>
          <span style={circleStyle(comment)}></span>
          <span style={style.name}>{comment.name}</span>
          <p style={style.comment}>{comment.comment}</p>
        </li>
      );
    });

  return (
    <Drawer
      className="commentList"
      style={style.drawer}
      ModalProps={{ disablePortal: true, disableEnforceFocus: true }}
      anchor="right"
      open={props.isChatOpen}>
      <ul style={style.ul}>{comments}</ul>
      <div ref={ref}></div>
    </Drawer>
  );
};

export default CommentList;
