export enum CharacterType {
  Guide = 'guide',
  Boy = 'boy',
  Girl = 'girl',
  Baby = 'baby',
  Balloon = 'balloon',
  Bear = 'bear',
  Dog = 'dog',
  Blackcat = 'blackcat',
  Tigercat = 'tigercat',
  Whitecat = 'whitecat',
}

export class Character {
  private readonly name: string;
  private readonly characterType: CharacterType;

  constructor(name: string, characterType: CharacterType) {
    this.name = name;
    this.characterType = characterType;
  }

  getName() {
    return this.name;
  }

  getCharacterType() {
    return this.characterType;
  }

  getIconSize(characterType: CharacterType) {
    switch (characterType) {
      case CharacterType.Guide:
        return [256 / 3, 238 / 3];
      case CharacterType.Boy:
        return [28, 78];
      case CharacterType.Girl:
        return [32, 68];
      case CharacterType.Baby:
        return [34, 48];
      case CharacterType.Balloon:
        return [18, 44];
      case CharacterType.Bear:
        return [30, 50];
      case CharacterType.Dog:
        return [30, 48];
      case CharacterType.Blackcat:
        return [58, 46];
      case CharacterType.Tigercat:
        return [58, 48];
      case CharacterType.Whitecat:
        return [34, 38];
    }
  }
}
