import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import moment from 'moment';

import { ja, en } from '../core/i18n/vtour';
import { useLang } from './hooks/useLang';
import { updateIsLoading } from '../core/reducer';
import { DispatchContext } from '../core/contexts';
import { EventsRepository } from '../repositories/events.repository';
import { Token } from '../domains/user/token';
import { useCheckbox } from './hooks/useCheckbox';

import VtourOverlay from '../components/vtour/VtourOverlay';
import VtourModal from '../components/vtour/VtourModal';
import VtourDate from '../components/vtour/VtourDate';
import VtourForm from '../components/vtour/VtourForm';
import VtourCheck from '../components/vtour/VtourCheck';
import VtourUrl from '../components/vtour/VtourUrl';
import VtourButton from '../components/vtour/VtourButton';

interface Props extends RouteComponentProps {}

const VtourContainer = (props: Props) => {
  const dispatch = React.useContext(DispatchContext);
  const [token, setToken] = useState<number>();
  const isCommentEnabled = useCheckbox(0);
  const isLandmarkCommentEnabled = useCheckbox(0);
  const isEmojiEnabled = useCheckbox(0);
  const isCommentListEnabled = useCheckbox(0);
  const isLocateEnabled = useCheckbox(0);

  const [mapID, setMapID] = useState('');
  useEffect(() => {
    const mapID = props.location.pathname.split('/')[1];
    setMapID(mapID);
  }, [props.location.pathname, mapID]);

  const [vtourUrl, setVtourUrl] = useState('');
  const eventsRepository = React.useRef(new EventsRepository());
  function createVtourId() {
    dispatch(updateIsLoading(true));
    const token = new Token().getToken();
    setToken(token);
    const param = `${token},${mapID},${limit},${isCommentEnabled.value},${isLandmarkCommentEnabled.value},${isEmojiEnabled.value},${isCommentListEnabled.value},${isLocateEnabled.value}`;
    const url = `/vtour/`;
    const protocol = token + mapID;

    eventsRepository.current
      .post(protocol, startDate)
      .then(() => {
        gtag('event', 'set options', {
          event_category: 'vtour',
          event_label: `[${param}]`,
        });

        setVtourUrl(`${url}${btoa(param)}`);
        dispatch(updateIsLoading(false));
      })
      .catch((error) => {
        dispatch(updateIsLoading(false));
        props.history.push(
          `/error/${protocol}`,
          props.history.location.pathname,
        );
      });
  }

  const [startDate, setStartDate] = useState<string>('');
  function convertDate(date: moment.Moment): string {
    return date?.format('YYYY-MM-DDTHH:mm');
  }
  useEffect(() => {
    // const date = new Date();
    // const utc = date.getTime();
    const date = moment();
    setStartDate(convertDate(date));
  }, []);

  function handleDateChange(date: moment.Moment): void {
    setStartDate(convertDate(date));
  }

  const [limit, setLimit] = useState(20);
  useEffect(() => {
    setVtourUrl('');
  }, [
    startDate,
    limit,
    isCommentEnabled.value,
    isLandmarkCommentEnabled.value,
    isEmojiEnabled.value,
    isCommentListEnabled.value,
    isLocateEnabled.value,
  ]);

  function goMap() {
    gtag('event', 'click', {
      event_category: 'vtour',
      event_label: `バーチャルツアー画面に入る`,
    });

    props.history.push(`${vtourUrl}/${token}`);
  }

  const lang = useLang({ ja, en });

  return (
    <IntlProvider locale={lang.language} messages={lang.message}>
      <VtourOverlay>
        <VtourModal>
          <VtourDate
            startDate={startDate}
            handleDateChange={handleDateChange}
          />
          <VtourForm limit={limit} setLimit={setLimit} />
          <VtourCheck
            isCommentEnabled={isCommentEnabled}
            isLandmarkCommentEnabled={isLandmarkCommentEnabled}
            isEmojiEnabled={isEmojiEnabled}
            isCommentListEnabled={isCommentListEnabled}
            isLocateEnabled={isLocateEnabled}
          />
          <VtourUrl
            createVtourId={createVtourId}
            url={vtourUrl}
            token={token}
          />
          <VtourButton url={vtourUrl} goMap={goMap} />
        </VtourModal>
      </VtourOverlay>
    </IntlProvider>
  );
};

export default VtourContainer;
