import React from 'react';
import { FormattedMessage } from 'react-intl';

type Props = {
  isGuideMode: boolean;
  handleBrowseModeClick: () => void;
};

const ConnectionBrowse = React.memo((props: Props) => {
  const style: { [key: string]: React.CSSProperties } = {
    p: {
      fontSize: '14px',
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  };

  const handleClick = React.useCallback(() => {
    props.handleBrowseModeClick();
  }, [props]);

  const button = props.isGuideMode ? (
    <p style={style.p} onClick={handleClick}>
      <FormattedMessage id="ConnectionBrowse.BrowseModeForVtour" />
    </p>
  ) : (
    <p style={style.p} onClick={handleClick}>
      <FormattedMessage id="ConnectionBrowse.BrowseMode" />
    </p>
  );

  return button;
});

export default ConnectionBrowse;
